.search-popup-overlay {
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.308);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9015;
    backdrop-filter: blur(5px);
}

.search-container {
  //  top:30%;
    width: 50vw;
    align-items: center;
    border-radius: 10px;
    transition: height 0.3s ease; // Smooth height transition
    overflow: hidden; // Hide overflow to smooth the transition
    position: relative; // Position relative for absolute positioning of child elements
    height: 10.14vh; // Default height
    max-height: 50.6vh;
    background-color: transparent;


    &.with-suggestions {
        border-radius: 8px;
        background: transparent;
        height: 100%; // Height when suggestions are present
    }

    &-search-popup {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        background: transparent;
        border-radius: 10px;
        justify-content: center;
        transition: height 0.3s ease; // Smooth height transition
        height: 100%; // Default height

        &-search-input {
            border: 1.97px solid #00BCEB;
            display: flex;
            align-items: center;
            width: 100%;
            background-color: #2A2F33;
            border-radius: 8px;

            &-container {
                display: flex;
                align-items: center;
                width: 90%;
                padding: 6px; // Add padding if needed
                margin: 6px;
                background-color: #393D42;
                border-radius: 6px;
                height: 100%;

                &-search-icon {
                    width: 24px; // Set a fixed width for the icon
                    height: 24px; // Set a fixed height for the icon
                    margin-right: 10px;
                    margin-left: 10px; // Space between the icon and input field
                    cursor: pointer; // Change cursor to pointer to indicate it's clickable
                }

                input {
                    flex: 1; // Make the input field take up the remaining space
                    border: none;
                    outline: none;
                    font-size: 16px;
                    background-color: #393D42;
                    color: #FFFFFF;
                    padding: 8px 0px;

                }

                .clear-text {
                    margin: 2px;
                    cursor: pointer;
                    color: #FFFFFF;
                    font-size: 16px;
                    transition: color 0.3s ease, transform 0.3s ease;
                    font-family: 'CiscoSansTT-Medium';

                    &:hover {
                        color: #00BCEB;
                        transform: scale(1.1);
                    }
                }

            }

            &-clear-text {
                font-family: 'CiscoSansTT-Medium';
                width: 10%;
                position: relative;
                display: flex;
                justify-content: center;
                cursor: pointer;
                color: #FFFFFF;
                font-size: 16px;

            }
        }


    }

    &-suggestions-list {
        position: absolute;
        left: 0;
        margin-top: 5px;
        width: 90%;
        max-height: 100%;
        overflow-y: auto;
        background: #1F2226;
        border-radius: 10px;
        transition: opacity 0.3s ease, height 0.3s ease;
        opacity: 0;
        height: 0;
        visibility: hidden;
        &-results-count {
            max-width: 40vw;
            font-size: 12px;
            font-weight: bold;
            color: #9E9EA2;
            padding: 6.8px;
            padding-bottom: 3px;
            padding-left: 10px;
        }
        &.visible {
            opacity: 1;
            height: 80%;
            visibility: visible;
        }
        &-suggestion-item {
            padding: 10px;
            cursor: pointer;
            font-family: 'CiscoSansTTLight';
            color: #FFFFFF;
            border-radius: 10px; /* Rounded corners for card effect */
            margin: 5px;
            background: #000000; /* Background color for card */
            transition: background 0.3s ease;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-sessionCode{
                font-size: 8px;
                font-family:'CiscoSansTTRegular'; ;
                font-weight: bold;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            &:hover {
                background: #0D274D; /* Highlight color on hover */
                color: #ffffff; /* Text color on hover */
        
                .highlighted-text {
                    background-color: #0D274D;
                    border: 1px solid #005279;
                    background: #0D274D;
                }
        
                .category {
                    color: #ffffff;
                }
            }
        
            .content {
                display: flex;
                flex-direction: column; /* Stack name and category vertically */
                flex: 1;
            }
        
            &-name {
                max-width: 40vw;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
                overflow: hidden;
                white-space: normal;       /* Allow text to wrap to the next line */
                word-wrap: break-word;     /* Break long words if necessary */
                overflow-wrap: break-word;
            }
        
            .category {
                overflow-y: none;
                max-width: 40vw;
                font-size: 12px;
                color: #adb3bb;
                margin-top: 2px; /* Space between name and category */
                text-align: left;
                white-space: normal;       /* Allow text to wrap to the next line */
                word-wrap: break-word;     /* Break long words if necessary */
                overflow-wrap: break-word;
                height: fit-content;
                max-height: 9vh;
                /* Optional hover effect */
                &:hover {
                    color: #ffffff; /* Text color on hover */
                }
                &.expanded {
                    height: fit-content;
                    max-height: fit-content;
                    /* Large max-height for expanded state */
                    -webkit-mask-image: none;
              
                }
            }
            .category.masked-content {
                -webkit-mask-image: linear-gradient(to bottom, black 20%, transparent 100%);
              }

            &-read-more-btn:focus {
                outline: none;
              }
          
              &-read-more {
                font-family: 'CiscoSansTTBold';
                cursor: pointer;
                color: #FFFFFF;
                font-size: 0.6vw;
                font-weight: 400;
                margin-top: 5px;
                 
                border: none;
                /* Remove any border */
                padding: 0;
                /* Remove padding if any */
                display: inline-block;
                /* Ensure it stays inline */
              }
        
            &-arrow-button {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:after {
                    color: #FFFFFF;
                    font-size: 16px;
                }
            }
        }
  
    }
    
    &-suggestions-list::-webkit-scrollbar {
        display: none;
      }

}



.highlighted-text {
    display: inline-block; /* Ensures each letter is wrapped as an inline-block */
    padding: 1px; /* Creates a small box around the letter */
    background-color: #00BCEB; /* Box color for highlighting */
    background: #0D274D;
    color: #FFFFFF; /* Text color inside the box */
    border-radius: 3px; /* Optional: gives the box slightly rounded corners */
    margin: 0 0.5px; /* Adds a bit of spacing between the highlighted letters */
    font-weight: bold; /* Makes the letter more prominent */
    border: 2px solid rgba(0, 217, 255, 0.5); /* Adds a semi-transparent white border */
    
    &:hover {
        background-color: #1291CE;
        border: 1px solid #005279;
        background: #1291CE;
    }
    .expanded {
        max-height: 13vh;
        /* Large max-height for expanded state */
        -webkit-mask-image: none;
  
      }
}

.close-mark {
    position: absolute;
    width: 120px;
    height: 30px;
    left: 25px;
    top: 29px;
    background: #B92948;
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'CiscoSansTT';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #FFFFFF;
}

.left-arrow {
    width: 15px;
    /* Adjust size as needed */
    height: 15px;
    /* Adjust size as needed */
    margin-right: 8px;
    /* Adjust margin to separate icon from text */
}