@import 'fonts';

/* Reset default browser styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

/* Full page container */
.full-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* Full page container */
.full-ai-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.background-blur-left-qr {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  z-index: 10000;
  backdrop-filter: blur(5px);
  /* Add blur effect */
}

.background-blur-getdirections-qr {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  z-index: 10003;
  backdrop-filter: blur(5px);
  /* Add blur effect */
}

/* Full page container */
.android-full-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.pointr-container {
  display: flex;
  flex-direction: column;
  z-index: 899;
}

/* Main container */
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Android Main container */
.android-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Left side container */
.left-side-container {
  display: flex;
  flex-direction: column;

}

/* Right side container */
.right-side {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Align items to the bottom of the container */
}

.right-side-container {
  display: flex;
  flex-direction: column;
}

/* Component styling */
.component {
  margin: 0.5rem;
  background-color: #e9ecef;
  padding: 1rem;
  border-radius: 4px;
  width: 120px;

}

/* Scrollable list */
.scrollable-list {
  max-height: 150px;
  /* Adjust height as needed */
  overflow-y: auto;
}

/* List item */
.component ul {
  list-style-type: none;
  padding: 0;
}

.component li {
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}

/* Logo styling */
.left-logo,
.right-logo {
  width: 50px;
  height: 50px;
  background-color: #ddd;
}

/* Weather component */
.weather-component {
  flex: 1;
  text-align: center;
}

.left-container {
  position: absolute;
  z-index: 1000;
  top: 13.68vh;
  width: 17vw;
  left: 1.20vw;

  &-wrapper {
    box-shadow: -20px 0px 18px rgba(0, 0, 0, 0.559);
    position: relative;
    border-radius: 20px;
    width: 17vw;
    z-index: 999;
    padding: 0 15px;
    overflow: auto;
    height: 50vh;
    max-height: 50vh;
    background-color: #1F2226;
    border-top: 1px solid #31353A;
    border-left: 0.5px solid #31353a9c;
    border-right: 0.5px solid #31353A;
    border-radius: 20px;

    .section-header {
      background-color: #1F2226;
      display: flex;
      flex-direction: column;
      align-items: left;
      padding-top: 1.865vh;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
    }

    .section-header h1 {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: "CiscoSansTT-Medium";
    }

    .section-header h2 {
      font-size: 12px;
      color: #aaa;
      margin-bottom: 10px;
      font-family: "CiscoSansTTLight";
    }

    .section-items {
      background-color: #1F2226;
      flex-direction: column;
      align-items: left;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
      overflow: auto;
      height: 85%;
      padding-bottom: 1.2vh;
      mask-image: linear-gradient(to bottom, black 85%, transparent 95%);
      -webkit-mask-image: linear-gradient(to bottom, black 85%, transparent 100%);
      transition: -webkit-mask-image 0.3s ease, mask-image 0.3s ease;
    }


    /* Hide scrollbar for Chrome, Safari and Opera */
    .section-items::-webkit-scrollbar {
      display: none;
    }

  }

  &-wrapper::-webkit-scrollbar {
    display: none;
  }

  &-level-selector {
    box-shadow: -20px 0px 18px rgba(0, 0, 0, 0.559);
    position: relative;
    border-radius: 20px;
    width: 17vw;
    z-index: 999;
    padding: 0 15px;
    height: 50vh;
    max-height: 50vh;
    background-color: #1F2226;
    border-top: 1px solid #31353A;
    border-left: 0.5px solid #31353a9c;
    border-right: 0.5px solid #31353A;
    border-radius: 20px;
    &-section-header {
      background-color: #1F2226;
      display: flex;
      flex-direction: column;
      align-items: left;
      padding-top: 1.865vh;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
      position: relative;
    }

    &-section-header h1 {
      font-size: 15px;
      padding-bottom: 5px;
      font-family: "CiscoSansTT-Medium";
    }

    &-section-header h2 {
      font-size: 12px;
      color: #aaa;
      margin-bottom: 10px;
      font-family: "CiscoSansTTLight";
    }

    &-section-items {
      overflow: auto;
      background-color: #1F2226;
      flex-direction: column;
      align-items: left;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
      height: fit-content;
      padding-bottom: 1.2vh;
      mask-image: linear-gradient(to top, black 85%, transparent 95%);
      -webkit-mask-image: linear-gradient(to top, black 98%, transparent 100%);
      transition: -webkit-mask-image 0.3s ease, mask-image 0.3s ease;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    &-section-items::-webkit-scrollbar {
      display: none;
    }

  }

  &-level-selector::-webkit-scrollbar {
    display: none;
  }

  &-footer {
    box-shadow: -20px 10px 18px rgba(0, 0, 0, 0.559);
    margin-top: 0.6218905472636815vh;
    width: 17vw;
    height: 34vh;
    background-color: #1F2226;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;
    border: 2px solid #0B99F7;
    border-color:#0B99F7;
    //border-image-source: linear-gradient(180deg, #0B99F7 0%, #00BCEB 100%);
    flex-direction: column;
    align-items: center;
    &-inner {
      width: 100%;
      height: 100%;
      background-color: #000000;
      z-index: 999;
      position: relative;
      top: 1%;
      left: 0.5%;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-top {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;

        &-left {
          flex-direction: column;
          display: flex;
          font-size: 20px;
          margin-top: 4px;
          font-family: "CiscoSansTTBold";
          text-align: center;

        }

        &-left-bottom {
          color: #FFFFFF70;
          font-size: 11px;
          display: flex;
          margin-top: 5px;
          font-family: "CiscoSansTT-Medium";
          text-align: center;
        }

        &-outer {
          width: 5.62vw;
          height: 60px;
          background: linear-gradient(45deg, rgba(0, 255, 0, 0.8), rgba(0, 200, 255, 0.8));
          z-index: 1000;
          border-radius: 10px;
        }

        &-right {
          position: absolute;
          /* Position it to overlap the left element */
          top: -5px;
          right: 0.5px;
          /* Adjust as needed to position it */
          width: 5.95vw;
          height: 160px;
          background-image: url('../assets/images/navigation.png');
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 1001;
          /* Higher z-index to be on top of the left element */
        }

      }

      &-outer-container {
        position: relative;
        width: 100%;
        height: 70%;
        border-radius: 10px;
        z-index: 10001;
        /* Ensure the container has a defined position */
        background-color: #f0f0f000;
        /* Optional, for better visibility */

        .outer-outer-1 {
          width: 6.94vw;
          height: 13.10vh;
          box-sizing: border-box;
          position: absolute;
          border: 2.18151px solid #FFFFFF;
          border-radius: 8px;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          z-index: 10002;
          opacity: 0.9;

          &-active {
            border: 2.18151px solid #FFFFFF;
            width: 10vw;
            height: 19vh;
            box-sizing: border-box;
            position: absolute;
            border-radius: 10px;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            z-index: 10002;
            opacity: 0.9;
          }
        }

        .outer-outer-2 {
          width: 7.94vw;
          height: 14.92vh;
          border: 1.09075px solid #8ce261;
          border-radius: 22.09px;
          box-sizing: border-box;
          position: absolute;
          border: 2.18151px solid #7FE14F;
          border-radius: 17.91px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          z-index: 10003;
          opacity: 0.6;
        }

        .outer-outer-3 {
          box-sizing: border-box;
          position: absolute;
          width: 8.92vw;
          height: 16.8vh;
          border: 1.09075px solid #8ce261;
          border-radius: 21.3664px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          z-index: 10004;
          opacity: 0.4;
        }

        // common.scss
        .outer-bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.62vw;
          height: 10.6vh;
          position: relative;
          background-size: contain;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-repeat: no-repeat;
          border-radius: 5px;

          canvas {
            margin: 0;
            width: 100%;
            height: 100%;
            max-width: 5.62vw;
            max-height: 10.6vh;
            border-radius: 5px;
            object-fit: contain;
            background: transparent;
          }

          &-active {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-size: contain;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            border-radius: 5px;

            canvas {
              margin: 0;
              width: 100%;
              height: 100%;
              max-width: 9vw;
              max-height: 19vh;
              border-radius: 5px;
              object-fit: contain;
              background: transparent;
            }
          }
        }

        &-close-bottom {
          flex-direction: column;
          display: flex;
          font-size: 10px;
          bottom: 4px;
          font-family: "CiscoSansTTBold";
          cursor: pointer;

        }
      }

      &-bottom {
        flex-direction: column;
        display: flex;
        font-size: 11px;
        margin: 10px;
        font-family: "CiscoSansTTRegular";
        cursor: pointer;
        border: 1.52px solid #FFFFFF;
        border-radius: 25px;
        transition: transform 0.2s ease;
        padding: 5px;
        &-active {
          flex-direction: column;
          display: flex;
          font-size: 11px;
          margin: 10px;
          font-family: "CiscoSansTTRegular";
          cursor: pointer;
          border: 0px solid #FFFFFF;
          border-radius: 25px;
          transition: transform 0.2s ease;
          padding: 5px;
        }
      }


    }

    &-active {
      flex-direction: column;
      align-items: center;
      border-radius: 20px;
      z-index: 1999;
      transform: scale(1.5);
      position: fixed;
      top: 25%;
      left: 40%;
      width: 20%;
      height: 42%;
      background-color: 1F2226;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10004;
      backdrop-filter: blur(100000px);
      box-shadow: 0 400px 800px rgba(0, 0, 0, 0.2);
      z-index: 19004;
      border: 2px solid #0B99F7;
      border-color:#0B99F7;
    }
    &-active::after {
      content: "";
      position: absolute;
      width: 1.3vw;
      height: 2.8vh;
      background-color: #0B99F7; /* Blue dot */
      border-radius: 50%;
      left: 96.6%;  /* Adjust to move inside/outside border */
      bottom: 8%; /* Adjust to move inside/outside border */
      z-index: 9004;
      border: 2.48px solid #FFFFFF
    }

  }
  &-footer::after {
    content: "";
    position: absolute;
    width: 1.3vw;
    height: 2.6vh;
    background-color: #0B99F7; /* Blue dot */
    border-radius: 50%;
    left: 95%;  /* Adjust to move inside/outside border */
    bottom: 7%; /* Adjust to move inside/outside border */
    z-index: 9004;
    border: 2.48px solid #FFFFFF
  }
  /* Styles for the component when it's tapped/active */

}


.main-container-zone-tap {
  position: absolute;
  z-index: 999;
  top: 0vh;
  width: 17vw;
  left: 1.20vw;
}

.pin-icon {
  position: relative;
  width: 65px;
  height: 65px;
  background-color: transparent;
  background-image: url('../assets/images/pin-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  top: 5px;
  bottom: 10px;
}

.circle-badge {
  position: absolute;
  top: 6px;
  left: 12.5px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  font-family: "CiscoSansTT-Medium";
  border: 2px solid #fff;
}

.icon-badge {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.left-container-item {
  display: flex;
  flex-direction: row;
  background-color: #2A2F33;
  border-radius: 15px;
  margin: 10px 0 0 0;
  cursor: pointer;
  align-items: center;

  &-left {
    opacity: 1;
    margin: 0;
    align-items: center;
    justify-self: unset;
    display: inline;
  }

  &-right {
    gap: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center; // Center content vertically
    flex-grow: 1; // Allow to take available space
    height: 100%;
    width: 80%;
    padding-right: 2px; // Adjust as needed to prevent overlap with the arrow
    justify-content: center;

    &-title {
      font-family: "CiscoSansTTBold";
      font-size: 14px;

      text-align: left; // Center text horizontally
      align-items: left;
      display: flex;
      justify-content: left;

      &.show-full {
        margin-top: 7%;
        display: block; // Make sure it's displayed when expanded
        opacity: 1; // Fully opaque when expanded
        max-height: none; // Remove height restriction when expanded
      }
    }

    &-description {
      font-family: "CiscoSansTTLight";
      font-size: 10px;
      padding-bottom: 5px;
      display: none; // Hide by default
      max-height: 1em; // Adjust this value for initial collapsed height
      overflow: hidden;

      &.show-full {
        margin-top: 5px;
        display: block; // Make sure it's displayed when expanded
        opacity: 1; // Fully opaque when expanded
        max-height: none; // Remove height restriction when expanded
      }
    }
  }

  &.expanded {
    gap: 2px;
    background-color: #3C444A;
    opacity: 1;
  }

  .arrow-container {

    flex: 3;
    display: none;
    margin-top: 8px;
    align-items: right;
    justify-content: right;

    .arrow-icon {
      display: inline;
      align-items: last baseline;
      transition: transform 0.5s ease;
      padding-right: 14px;

      &.up {
        transform: rotate(0deg);
      }

      &.down {
        transform: rotate(0deg);
      }
    }
  }

}
.left-container-building-selector {
  display: flex;
  align-items: center;       /* Vertically center items */
  justify-content: space-between;    /* Space items evenly */
  background: #2D3238;
  border-radius: 15px;
  margin: 10px 0;
  cursor: pointer;
  min-height: 7vh;
  position: relative;         /* Necessary for absolute positioning */
  color: #fff;                /* Text color */
  font-size: 16px;
  align-items: center;        /* Vertically center items */
  justify-content: center;     /* Horizontally center items */
  flex-direction: row;
  gap: 2vw;
  width: 100%;
  font-family: "CiscoSansTTLight";
  &-left-nav{
    width: 2vw;
    height: 2vw;
  }
  &-name{
    font-size: 16px;
  }

  &-right-nav{
    width: 2vw;
    height: 2vw;
  }

}
.left-container-level-selector-item {
  gap: 15px;
  display: flex;
  align-items: left;       /* Vertically center items */
  justify-content: space-between;    /* Space items evenly */
  background-color: #2A2F33;
  border-radius: 15px;
  margin: 10px 0;
  cursor: pointer;
  min-height: 7vh;
  position: relative;         /* Necessary for absolute positioning */
  color: #fff;                /* Text color */
  font-size: 16px;
  align-items: center;        /* Vertically center items */
  justify-content: left;     /* Horizontally center items */
  padding-left: 1vw;
  border:2px solid;
  flex-direction: row;
  &-name{
    font-size: 16px;
  }
  &-solid-rectangle{
    border-radius: 5px;
    width: 1.5vw;
    height: 1.5vw;
  }
}

.floor-marker {
  position: absolute;         /* Change to absolute positioning */
  top: 50%;                  /* Center vertically */
  color: #fff;               /* Marker color */
  width: 16px;               /* Set size for consistency */
  height: auto;              /* Keep aspect ratio */
}





.header-container {
  display: flex;
  background-color: #f8f9fa;
  position: absolute;
  background: transparent;
  z-index: 999;
  gap: 8px;
  right: inherit;
  width: 100%;
  padding-right: 1.9vw;
  background: linear-gradient(180deg, #101214cf 25.76%, rgba(16, 18, 20, 0) 92.42%);

  &-card-1 {
    border-radius: 20px;
    background-color: #1F2226;
    width: 23.076vw;
    height: 11.19vh;
    display: flex;
    margin-top: 10px;
    margin-left: 0.99vw;
    border-top: 1px solid #31353A;
    border-left: 0.5px solid #31353A;
    border-right: 0.5px solid #31353A;
    align-items: center;


    &-left {
      width: 38%;
      height: 100%;

      &-logo {
        background-image: url('../assets/images/cisco-gsx-logo.svg');
        height: 9.328358208955224vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 2px;
        margin-left: 15px;
        margin-top: 0.5px;
        margin-bottom: 0.5px;
        width: 7vw;
        object-fit: contain;
        position: relative;
      }


    }

    &-seperator {
      display: flex;
      height: 80%;
      width: 1px;
      background-color: #848080;
      position: relative;

    }

    &-right {
      width: 62%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: left;
      row-gap: 1.5vw;
      column-gap: 1vw;

      &-inner {
        width: 70%;
        max-width: 70%;
        flex-direction: column !important;
        position: relative;
        display: flex;
        margin-left: 1vw;
        flex-direction: row;
        justify-content: left;

        &-location {
          font-family: "CiscoSansTTLight";
          font-size: 25px;
          white-space: nowrap;
          /* Prevents wrapping to a new line */
          overflow: hidden;
          /* Hides overflow */
          text-overflow: ellipsis;
          /* Shows '...' for overflowed text */
        }

        &-level {
          font-family: "CiscoSansTTBold";
          font-size: 25px;
          white-space: nowrap;
          /* Prevents wrapping to a new line */
          overflow: hidden;
          /* Hides overflow */
          text-overflow: ellipsis;
          /* Shows '...' for overflowed text */
        }

      }

      &-dropdown-toggle {
        height: 0.625vw;
        width: 1.25vw;
        fill: #0000;
        border: #0000;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        background: #0000;
        border-radius: 0.625vw;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;

      }
    }

  }


  &-card-2 {
    border-top: 1px solid #31353A;
    border-left: 0.5px solid #31353A;
    border-right: 0.5px solid #31353A;
    border-radius: 20px;
    background-color: #1F2226;
    width: 33.8vw;
    height: 11.19vh;
    display: flex;
    margin-top: 10px;
    align-items: center;
    padding-left: 0.3vw;

    &-left {
      display: flex;
      height: 100%;
      align-items: center;
      width: 55%;
      padding: 14px;
      flex-direction: row;


      &-day {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 65%;
        justify-content: space-around;

        &-counter {
          font-family: "CiscoSansTT-Medium";
          font-size: 25px;
        }

        &-date {
          font-family: "CiscoSansTT-Medium";
          font-size: 15px;
          width: 100%;
        }
      }

      &-time-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 35%;
        justify-content: space-around;



        &-counter {
          font-family: "CiscoSansTT-Medium";
          font-size: 25px;
          opacity: 0;
        }

        &-time {
          color: #00BCEB;
          display: inline;
          justify-content: space-evenly;
          font-family: 'CiscoSansTT-Medium';
          display: flex;
          font-size: 14px;
          align-items: last baseline;
          gap: 10px;

        }
      }

      &-time {
        color: #00BCEB;
        display: flex;
        flex-direction: revert;
        width: 35%;
        justify-content: space-evenly;

        font-family: 'CiscoSansTT-Medium';
        display: flex;
        font-size: 14px;
        align-items: last baseline;
        height: 100%;
        margin-bottom: 4vh;
      }
    }

    &-seperator {
      display: flex;
      height: 80%;
      width: 1px;
      background-color: #848080;
      position: relative;
    }

    &-right {
      display: flex;
      height: 100%;
      align-items: center;
      width: fit-content;
      max-width: 45%;
      margin-left: 0.5vw;
      flex-direction: row;

      &-weather-icon {
        padding: auto;
        width: 6vw;
        height: 90%;
      }

      &-location {
        padding-left: 14px;

        &-name {
          font-family: "CiscoSansTTBold";
          font-size: 16px;
        }

        &-temperature {
          font-family: "CiscoSansTTBold";
          font-size: 19px;
        }

        &-weather-info {
          font-family: 'CiscoSansTTLight';
          font-size: 12px;
        }
      }

    }




  }

  &-card-3 {
    border-radius: 20px;
    background-color: #00000000;
    width: 26.2vw;
    height: 11.19vh;
    visibility: hidden;
    margin-top: 10px;
  }

  &-card-4 {

    margin-top: 10px;
    width: 13vw;
    overflow: hidden;
    border-radius: 20px;
    background: linear-gradient(101.67deg, #091931 14.29%, #020512 84.52%);
    height: 11.19vh;
    box-shadow: 0 0 1px 1px #00FFFF;
    display: flex;
    flex-direction: column;
    align-items: left;
    overflow-y: scroll;

    &-left {
      border-radius: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;


      &-title {
        border-bottom: 1px solid #5cfafa51;
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        width: 80%;
        font-family: 'CiscoSansTT-Medium';
        font-style: normal;
        font-size: 11px;
        align-items: center;
      }

      &-logo {
        background-image: url('../assets/images/spaces-logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        align-items: center;
        width: 10.58vw;
        height: 14.9vh;
        margin: 12px;
      }
    }

    &-scroll-bottom {
      height: 29.8vh;
      width: 13vw;
      display: flex;
      flex-direction: row;
      align-items: left;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 5px;
      padding-bottom: 5px;

      &-title {
        font-size: 22px;
        align-items: left;
        font-family: "CiscoSansTT-Medium";
        width: 100%;
        height: 8.7vh;
        text-align: center;
        /* Center the text */
      }

      &-logo {
        background-image: url('../assets/images/qr-sample.png');
        background-repeat: no-repeat;
        background-size: contain;
        align-items: left;
        width: 4.6vw;
        height: 8.7vh;
      }
    }
  }

  &-card-4::-webkit-scrollbar {
    display: none;
  }
}


/* Blurred background overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100100;
}




/* Centered popup */
.popup {
  margin: auto;
  padding: 1vw;
  width: 53%;
  max-height: 50%;
  position: relative;
  background: #1D2023;
  box-sizing: border-box;
  border-radius: 1vw;
  font-family: CiscoSansTT;
  font-style: normal;
}

.popup-content {
  width: 19.84vw;
  /* Adjust as needed */
  height: 12.43vh;
  /* Adjust as needed */
  display: flex;
  z-index: 100100;
  flex-direction: row;
  align-items: center;
  border-width: 2px;
  border-color: #00BCEB;
  margin-left: 4%;
}

.popup-heading {
  display: inline-block;
  font-family: "CiscoSansTTRegular";
  font-size: 1.8vw;
  margin: 1vw;
  font-weight: 300;
  display: inline-block;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
}

.popup-subheading {
  margin: 1vw;
  font-size: 1.8vw;
  font-family: "CiscoSansTTRegular";

}

.popup-circle {
  position: relative;
  margin: 10px;
  width: 4vw;
  height: 7.4vh;
  background: #26282b;
  border-color: #00BCEB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  flex-direction: column;
  border: 1px solid #383b3d;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
  float: right;
}


/* Add this to your existing CSS */
.popup-circle.selected {
  position: relative;
  border-radius: 50%;
  border: 4px double #00BCEB;
  box-shadow: 0px 5px 20px #00BCEB;
  line-height: 4.6vw;
  font-size: 1.7vw;
  font-style: bold;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
  cursor: allowed;
  opacity: 1;
  background: #000000;

  &-you-are-here {
    margin-top: -1vw;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-family: CiscoSansTTRegular;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 3.625vw;
    background: #000000;
    border-radius: 1vw;
    color: #ffffff;
    font-weight: 400;
    font-size: 0.6375vw;
    line-height: 0.775vw;
    border-bottom: 1px solid #00BCEB;
  }
}

.building-tab-navigation {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  column-gap: 15px;

}

.building-tab-button {

  border-radius: 1vw;
  padding: 10px;
  cursor: pointer;
  background: #26282b;
  color: white;
  font-family: CiscoSansTTRegular;

}

.building-tab-button.active {
  box-shadow: 0px 1px 15px #00BCEB;
  border: 2px solid #00BCEB;
  border-radius: 1vw;
  color: white;
  font-family: CiscoSansTTRegular;
}


.seperator {
  height: 80%;
  width: 1px;
  background-color: #848080;
  position: relative;
  top: 10%;
}

.circle {
  width: 0.53vw;
  height: 0.99vh;
  background-color: #00FFFF;
  border-radius: 50%;
  box-shadow: 0 0 40px 2px #00FFFF
}

.right-ai-container-wrapper {
  position: absolute;
  bottom: 2.87vh;
  width: 32vw;
  right: 1.50vw;
  z-index: 999;
  background: #1F2226;
  max-height: 82vh;
  border: 2px solid #1F2226;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  &-title {
    border-radius: 5px;
    margin-top: 10px;
    background-color: #1F2226;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
    &-text{ 
      width: 85%;
      text-align: center;
      font-family: "CiscoSansTTBold";
      font-size: 20px;
    }
    &-button-close {
      cursor: pointer;
      transform: scale(1.2);
      width: 1.5vw;
      align-content:last baseline;
    }
  }

  &-container-1 {
    padding-left: 0.5vw;
    background: #181b1e;
    color: white;
    font-family: "CiscoSansTTLight";
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;


    &-left {
      color: white;
      font-family: "CiscoSansTTLight";
      border-radius: 5px;
      min-width: 50%;
      height: 100%;
      max-height: 44vh;
      min-height: 44vh;
      overflow-y: auto; 
      background: #181b1e;
      border-top-right-radius: 0px;
      &-item {
        border-radius: 5px;
        background-color: #1F2226;
        flex-direction: column;
        align-items: left;
        width: 100%;
        font-family: "CiscoSansTT-Medium";
        height: 6vh;
        display: flex;
        flex-direction: row;
        background-color: #2A2F33;
        margin: 8px 0 0 0;
        cursor: pointer;
        align-items: center;
        overflow: hidden;
        gap: 0.2vw;
        &-left {
          overflow: hidden;
          opacity: 1;
          margin: 0;
          display: flex;
          width: 25%;
          height: inherit;
          justify-content: center;
          /* Center horizontally */
          align-items: center;
          /* Center vertically */

          &-icon {
            position: relative;
            width: 50%;
            height: 50%;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }

        &-right {
       
          gap: 2px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly; // Center content vertically
          height: 100%;
          width: 65%;
          justify-content: center;
          overflow: hidden;
          padding: 2px;
          height: fit-content;
          max-height: inherit;
          &-title {
            font-family: "CiscoSansTTLight";
            font-size: 11px;
            text-align: left; // Center text horizontally
            align-items: left;
            display: flex;
            justify-content: left;
            max-height: 4.5vh;

          }

          &-sub-title {
            font-family: "CiscoSansTTBold";
            font-size: 8px;
            padding-bottom: 5px;
            max-height: fit-content; 
            overflow: hidden;
          }

        }

        &-right-corner {
          opacity: 1;
          margin: 0;
          align-items: right;
          justify-self: right;
          display: inline;
          width: 15%;

          &-icon {
            display: inline;
            align-items: last baseline;
            transition: transform 0.5s ease;
            padding-right: 14px;
            background-image: url('../assets/images/ai/play-icon.svg');
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        &.selected {
          background-color: #000;
          /* Slightly enlarge the selected item */
        }
      }


      &-item::-webkit-scrollbar {
        display: none;
      }

    }

    &-left::-webkit-scrollbar {
      display: none;
    }

    &-right {
      background-clip: content-box;
      padding: 10px;
      color: white;
      font-family: "CiscoSansTTLight";
      border-radius: 5px;
      min-width: 50%;
      height: 45.5vh;
      overflow-y: auto;
      max-height: 45.5vh;
      padding-right: 0.8vw;
      padding-left: 0.5vw;
      gap: 2px;
      background-color: #000000;
      overflow: hidden;
      &-top {
        
        display: flex;
        flex-direction: column;
        opacity: 1;
        display: flex;
        width: 100%;
        height: 15%;
        max-height: 15%;

        justify-content: left;
        /* Center horizontally */
        align-items: left;
        background-image: url('../assets/images/ai/banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &-left-image {
          position: relative;
          width: 3.5vw;
          height: auto; /* Works for images */
          left: 0.4vw;
      }

        &-title {
         // border-bottom:0.57px solid var(--Tertiary-Palette-Ash, #E2E2E2);
          color: #889099;
          width: 63%;
          padding: 2px;
          margin: 0.2vw;
          font-family: "CiscoSansTTBold";
          font-size: 8px;
          min-height: 2vh;
          max-height: 2vh;
          text-align: left; // Center text horizontally
          align-items: left;
          display: flex;
          justify-content: left;
          overflow: hidden;
          text-overflow: ellipsis; /* Add ellipsis to truncated text */
        }
        &-bridge {
          width: 95%;
          height: 0.2vh;
          gap: 0px;
          border: 2px 0px 0px 0px;
          color: #ffffff;
          background-color: #ffffffd1;
          align-items: center;
          display: flex;
        }
      }

      &-bridge {
        width: 95%;
        height: 1%;
        gap: 0px;
        border: 2px 0px 0px 0px;
        opacity: 0px;
        color: #ffffff;
        background-color: #ffffffd1;
        align-items: center;
        display: flex;
      }

      &-bottom {
        padding:0.5vw;
        background: #000000;
        position: relative;
        display: flex;
        flex-direction: column;
        opacity: 1;
        min-height: 60%;
        max-height: 60%;
        gap: 8px;
        overflow: hidden;
        padding-bottom: 0.1vw;
        &-banner {
          display: flex;
          flex-direction: row;
          opacity: 1;
          margin: 0;
          width: 100%;
          height: 100%;
          justify-content: space-between;


          &-left {
            padding-top: 5px;
            font-family: "CiscoSansTTBold";
            font-size: 11px;
            text-align: left; // Center text horizontally
            align-items: left;
            display: flex;
            justify-content: left;
            max-height: 5%;

          }

          &-right {
            display: flex;
            justify-content: right;
            background-color: #000000;
            &-icon {
              width: 15%;
              position: relative;
              cursor: pointer;
              padding-top: 1vh;
              padding-right: 1vh;
            }
          }

        }

        &-timings {
          display: flex;
          flex-direction: row;
          opacity: 1;
          margin: 0;
          width: 100%;
          height: 100%;
          justify-content: space-between;

          &-left {
            font-family: "CiscoSansTTBold";
            font-size: 11px;
            text-align: left; // Center text horizontally
            align-items: left;
            display: flex;
            justify-content: left;
           }

          &-right {
            font-family: "CiscoSansTTBold";
            font-size: 11px;
            text-align: left; // Center text horizontally
            align-items: left;
            display: flex;
            flex-direction: row;
            &-1 {
              font-family: "CiscoSansTTBold";
              font-size: 11px;
              text-align: left; // Center text horizontally
              align-items: left;
              display: flex;
              justify-content: left;
              border-right: 1px solid #FFFFFF40;
              padding-right: 3px;
            }
            &-2 { 
              padding-left: 3px;
              font-family: "CiscoSansTTBold";
              font-size: 11px;
              text-align: left; // Center text horizontally
              align-items: left;
              display: flex;
              justify-content: left;
            }
            
          }
        }

        &-location {
          font-family: "CiscoSansTT-Medium";
          font-size: 11px;
          text-align: left; // Center text horizontally
          align-items: left;
          display: flex;
          justify-content: left;
          &-icon {
            height: 2vh;
            width: 10%;
            position: relative;
          }
          &-name{
            max-height: 3.6vh;
            height: fit-content;
            width: 90%;
            font-family: "CiscoSansTT-Medium";
            font-size: 11px;
            text-align: left; // Center text horizontally
            align-items: left;
            display: flex;
            justify-content: ce;
            overflow: hidden;
          }
        }
        &-bridge {
          width: 100%;
          height: 0.1vh;
          gap: 0px;
          border: 2px 0px 0px 0px;
          color: #ffffff;
          background-color: #ffffffd1;
          align-items: center;
          display: flex;
        }
        &-description {
          font-family: "CiscoSansTTThin";
          font-size: 10px;
          text-align: left; // Center text horizontally
          align-items: left;
          display: flex;
          justify-content: left;
          text-align: left;
          overflow: auto;
          
          flex-direction: column;
          &-speaker-section {
            display: flex;
            flex-direction: column;
            opacity: 1;
            margin-top: 0.8vh;
            width: 100%;
            gap: 8px;
  
            &-details {
             
              overflow: hidden;
              overflow-y: auto;
              display: flex;
              flex-direction: column;
              opacity: 1;
              width: 100%;
              gap: 8px;
  
              &-header {
                font-family: "CiscoSansTTRegular";
                font-size: 9px;
                text-align: left; // Center text horizontally
                align-items: left;
                display: flex;
                justify-content: left;
              }
  
              &-value {
                font-family: "CiscoSansTTBold";
                font-size: 11px;
                text-align: left; // Center text horizontally
                align-items: left;
                display: flex;
                justify-content: left;
                
              }
            }
          }
        }

      }
      &-get-directions
      {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 18.5%;
        max-height: 18.5%;
        
        &-button {
        display: flex;
        text-align: center;
        justify-content: space-evenly;
        align-items: center;
        background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
        width: 90%;
        height: 50%;
        border: none;
        border-radius: 0.5vw;
        font-family: 'CiscoSansTTRegular';
        font-style: normal;
        font-weight: 600;
        font-size: 1.11vw;
        color: #FFFFFF;
        cursor: pointer;
      }
    }

      

    }

    &-right::-webkit-scrollbar {
      display: none;
    }
  }

  &-container-1::-webkit-scrollbar {
    display: none;
  }

  &-container-2 {
    color: white;
    font-family: "CiscoSansTTLight";
    border-radius: 21px;
    width: 100%;
    height: 100%;
    max-height: 32vh;
    overflow: auto;
    background: #1F2226;
    display: flex;
    flex-direction: row;

    &-left {
      padding-left: 1.2vw;
      margin-top: 0.6218905472636815vh;
      color: white;
      font-family: "CiscoSansTTLight";
      border-radius: 21px;
      width: 50%;
      overflow-y: auto;
      background: #1F2226;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      &-1 { 
        padding-top: 1vh;
        width: 100%;
        height: 35%;
      }
      &-2 {
        text-align: center;
        width: 100%;
        height: 12%;
        font-size: 15px;
        font-family: "CiscoSansTT-Medium";
      }
      &-3 {
        width: 100%;
        height: 25%;
        font-size: 10px;
        font-family: "CiscoSansTTLight";
        text-align: center;
      }
      
      &-4 {
          height: 25%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 4px;

          &-l {
            position: relative;
            width: 15%;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
          }
      
          &-m {
            position: relative;
            width: 15%;
            background-size: contain;
            background-repeat: no-repeat;
          }
      
          &-r {
            width: 15%;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }
    }
    &-vertical-line{
      margin-top: 2vh;
      width: 1px;
      height: 28vh;
      gap: 0px;
      border: 2px 0px 0px 0px;
      opacity: 0px;
      color: #ffffff;
      background-color: #ffffff;
      align-items: center;
      display: flex;;
    
    }
    &-right {
      margin-top: 0.6218905472636815vh;
      color: white;
      font-family: "CiscoSansTTLight";
      width: 50%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      background: #1F2226;
      flex-direction: row;
      padding-left: 1vw;
      &-top{
        margin-top: 6%;
        position: relative;
        height: 1%;
      }
      &-middle{
        height: 10%;
        display: flex;
        flex-direction: row;
        gap:0.2vw;
        &-green-dot{
          width: 0.8vw;
        }
        &-you{
          font-family: "CiscoSansTT-Medium";
          font-size: 14px;
        }
      }
      &-bottom{
        align-items: center;
        height: 60%;
          &-list {
            align-items: center;
            &-item {
              border: 2px solid var(--Tertiary-Palette-Ash, #E2E2E2);
              padding-left: 0.5vw;
              border-radius: 5px;
              background-color: #1F2226;
              align-items: left;
              width: 90%;
              font-family: "CiscoSansTT-Medium";
              font-size: 10px;
              overflow: auto;
              height: 4vh;
              display: flex;
              background-color: #2A2F33;
              border-color: #ffffff;
              margin: 8px 0 0 0;
              cursor: pointer;
              align-items: center;
              gap: 0.5vw;
      
              &.selected {
                background-color: #000;
                /* Slightly enlarge the selected item */
              }
            }
          }
      
      }
      &-selected-list {
      
        display: block;
        align-items: center;
        background-color: #1F2226;
        align-items: center;
        height: 100%;
  
        &-item {
          border: 2px solid var(--Tertiary-Palette-Ash, #E2E2E2);
          padding-left: 0.5vw;
          border-radius: 5px;
          align-items: left;
          width: 90%;
          font-family: "CiscoSansTT-Medium";
          font-size: 10px;
          overflow: auto;
          height: 4vh;
          display: flex;
          background-color: #2A2F33;
          border-color: #ffffff;
          margin: 8px 0 0 0;
          cursor: pointer;
          align-items: center;
          gap: 0.5vw;
  
          &-expand {
            margin-top: 1vh;
            height: 13vh;
            background-color: #000;
            width: 90%;
            border-left: 2px solid transparent; /* Border width */
            border-image-source: linear-gradient(27.89deg, #3B76EA 9.21%, #00BCEB 87.7%, #63FFF7 166.2%);
            border-image-slice: 1;
            &-h {
              padding-top: 1vh;
              height: 20%;
              display: flex;
              flex-direction: row;
              align-items: center;
              &-left {
                width: 2vw;
                height: 2vh;
              }
  
              &-right {
                text-align: center;
                font-size: 12px;
                align-content: center;
                font-family: "CiscoSansTTBold";
              }
            }
  
            &-b {
              padding: 5px;
              height:fit-content;
              font-size: 10px;
            }
          }

        }
        &-return-item {
          display: flex;
          flex-direction: row;
          padding-left: 0.5vw;
          border-radius: 5px;
          align-items: left;
          width: 90%;
          font-family: "CiscoSansTT-Medium";
          font-size: 10px;
          overflow: auto;
          height: 4vh;
          display: flex;
          border-color: #ffffff;
          margin: 8px 0 0 0;
          cursor: pointer;
          align-items: center;
          gap: 0.5vw;
          &-icon {
            width: 5%;
            position: relative;
            cursor: pointer;
          }
          &-label{
            width: 95%;
          padding-left: 0.5vw;
          border-radius: 5px;
          align-items: left;
          width: 90%;
          font-family: "CiscoSansTT-Medium";
          font-size: 10px;
          overflow: auto;
          display: flex;
          border-color: #ffffff;
          cursor: pointer;
          align-items: center;
          gap: 0.5vw;
          text-align: center;
        }
        }
      }
 
    }

    

  }
}

.right-container-wrapper {
  position: absolute;
  bottom: 2.87vh;
  width: 13vw;
  right: 1.50vw;
  z-index: 999;
  background: transparent;
  max-height: 84vh;



  .container {
    visibility: hidden;
    box-shadow: 19px -8px 18px rgba(0, 0, 0, 0.559);
    color: white;
    font-family: "CiscoSansTTLight";
    border-top: 1px solid #146557;
    border-left: 0.5px solid #146557a9;
    border-right: 0.5px solid #146557a9;
    border-radius: 21px;
    width: 13vw;
    height: 100%;
    overflow-y: auto;
    max-height: 1vh;
  }


  .ai-container {
    box-shadow: 19px -8px 18px rgba(0, 0, 0, 0.559);
    color: white;
    font-family: "CiscoSansTTLight";
    border-top: 1px solid #146557;
    border-left: 0.5px solid #146557a9;
    border-right: 0.5px solid #146557a9;
    border-radius: 21px;
    width: 13vw;
    height: 100%;
    overflow-y: auto;
    max-height: 38vh;
    align-items: center;
    background-color: #1F2226;
    padding-bottom: 5px;

  &-section {
    background-color: #1F2226;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    height: 100%;
    width: 100%;
    &-header {
      background-color: #1F2226;
      display: flex;
      flex-direction: column;
      align-items: center; /* Horizontal center alignment */
      justify-content: center; /* Vertical center alignment */
      text-align: center; /* Ensures multi-line text is center-aligned */
      padding: 5px;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
    }
  }
  
    &-logo {
      width: 100%;
      height: 14vh;
    }

    &-logo-name {
      font-size: 13px;
      background-color: #1F2226;
      display: flex;
      flex-direction: column;
      align-items: center; /* Horizontal center alignment */
      justify-content: center; /* Vertical center alignment */
      text-align: center; /* Ensures multi-line text is center-aligned */
      padding: 5px;
      width: 100%;
      font-family: "CiscoSansTT-Medium";
    }
        &-scan-your-badge-container {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 0.4vw;
          padding-top: 1vh;
    
          &-button {
            background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
            height: 5vh;
            width: 11.5vw;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #FFFFFF;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.75vw;
            cursor: pointer;
            transition: background-color 0.3s ease;
            /* Force single-line text */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
    
  }

  .container-2 {
    box-shadow: 20px 11px 18px rgba(0, 0, 0, 0.559);
    margin-top: 0.6218905472636815vh;
    color: white;
    font-family: "CiscoSansTTLight";
    border-top: 1px solid #31353A;
    border-left: 0.5px solid #31353A;
    border-right: 0.5px solid #31353A;
    border-radius: 21px;
    width: 13vw;
    height: 100%;
    min-height:25vh;
    overflow: hidden;
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .container-2::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .container::-webkit-scrollbar {
    display: none;
  }
    /* Hide scrollbar for Chrome, Safari and Opera */
  .ai-container::-webkit-scrollbar {
      display: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .cards::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .cards {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

  }

  .section {
    background-color: #1F2226;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 20px;
    padding: 10px;
    height: 100%;
    width: 100%;
    min-height:25vh;
  }

  .section-header {
    background-color: #1F2226;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 5px;
    width: 100%;
    font-family: "CiscoSansTT-Medium";
  }

  .section h1 {
    font-size: 15px;
    padding-bottom: 5px;
    font-family: "CiscoSansTT-Medium";
  }

  .section h2 {
    font-size: 12px;
    color: #aaa;
    margin-bottom: 10px;
    font-family: "CiscoSansTTLight";
  }

  .cards-container {
    /* Adjust as needed */
    overflow: auto;
    width: 100%;
    height: 100%;
    align-items: left;
    font-family: "CiscoSansTT-Medium";
    border-radius: 10px;
    max-height: 40vh;
  }

  .cards-container::-webkit-scrollbar {
    display: none;
  }

  .cards-container-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(2.8vw, 1fr)); // Responsive grid with auto-fill and min width
    gap: 0.9vw 0.7vw; // space between cards

  }

  .card-grid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-grid {
    border-radius: 14px; // rounded corners, adjust as needed
    display: flex;
    flex-direction: column;
    align-items: center; // center align items horizontally
    transition: transform 0.3s; // smooth transition for hover effect
    justify-content: center;
    width: 3.51vw; // fixed width of the card
    height: 6.6vh; // fixed height of the card
    background-color: #000000; // black background
    overflow: hidden; // hide overflow
    border-top: 1px solid #31353A;
    border-left: 1px solid #31353A;
    border-right: 1px solid #31353A;

    &:hover {
      transform: translateY(-5px); // lift the card on hover
    }
  }

  .facility-card-clicked {
    // Background color when the card is clicked
    box-shadow: 0px 0px 6px 2px #00BCEB;
    transform: scale(1.01); // Slightly enlarge the card when clicked
  }

  .icon-grid {
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .title-grid {
    font-size: 0.56vw; // font size of the title, adjust as needed
    color: #ffffff; // color of the title
    font-family: "CiscoSansTT-Medium";
    margin-top: 8px; // space between the box and title
    text-align: center; // center align text
  }

  .card {
    display: flex;
    align-items: left;
    background-color: #000;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 14px;
    width: 100%;
    justify-content: left;
    cursor: pointer;
    font-family: "CiscoSansTT-Medium";
    border-top: 1px solid #31353A;
    border-left: 1px solid #31353A;
    border-right: 1px solid #31353A;

    &:hover {
      background-color: #00BCEB; // Light blue color on hover
    }
  }

  .card-clicked {
    // Background color when the card is clicked
    background-color: #00BCEB; // Light cyan color
    transform: scale(1.01); // Slightly enlarge the card when clicked
  }

  .icon {
    margin-right: 0.7vw;
    background-image: url('../assets/images/guide-icon.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 0.66vw;
    height: 1.24vh;
  }

  .icon-hub-walks {
    background-image: url('../assets/images/guide-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 68%;
    height: 68%;
    align-items: center;
    justify-content: center;
  }


  .title-wrapper {
    margin-left: 0.33vw;
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .circle-badge-right-container {
    position: relative;
    width: 2.11vw;
    height: 3.9vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    font-family: "CiscoSansTT-Medium";
  }

  .title {
    margin-top: 2px;
    margin-left: 4px;
    font-size: 12px;
    font-family: "CiscoSansTTLight"
  }

  .sub-title {
    margin-left: 4px;
    margin-top: 0.25vh;
    font-size: 13px;
    font-family: "CiscoSansTTBold"
  }

  &-footer {
    width: 20vw;
    height: 17vh;
    background-color: #1F2226;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;

    &-outer {
      width: 85%;
      height: 80%;
      background: linear-gradient(45deg, rgba(0, 255, 0, 0.8), rgba(0, 200, 255, 0.8));
      z-index: 1000;
      border-radius: 10px;
    }

    &-inner {
      width: 99%;
      height: 98%;
      background-color: #1F2226;
      z-index: 1001;
      position: relative;
      top: 1%;
      left: 0.5%;
      border-radius: 10px;
      display: flex;
      flex-direction: row;

      &-left {
        display: flex;
        flex-direction: column;
        width: 60%;
        padding: 10px;

        &-top {
          background-image: url('../assets/images/navigation.png');
          background-size: contain;
          background-repeat: no-repeat;
          width: 5.62vw;
          height: 7.46vh;
        }

        &-bottom {
          font-size: 12px;
          margin-top: 10px;
        }
      }

      &-right {
        background-image: url('../assets/images/qr-sample.png');
        background-size: contain;
        background-repeat: no-repeat;
        align-items: center;
        width: 40%;
        height: 85%;
        background-position-y: 10px;
        background-position-x: -7px;
      }
    }
  }


}

.timer-container {
  position: fixed;
  bottom: 40vh;
  left: 22vw;
  width: 50vw;
  z-index: 99999;

  .timer {
    z-index: 99999;
    font-family: "CiscoSansTT-Medium";
    text-align: center;
    background-color: #c3c6cc;
    color: white;
    border-radius: 50%;
    width: 3.96vw;
    height: 7.46vh;
    margin: 20px auto;

    h1 {
      position: relative;
      top: 0.9950248756218906vh;
    }
  }

  p {
    font-size: 12px;
    text-align: center;
  }
}




.refresh-icon {

  width: 22px;
  /* Adjust size as needed */
  height: 22px;
  /* Adjust size as needed */
  margin-right: 8px;
  /* Adjust margin to separate icon from text */
}


.close-mark {
  position: absolute;
  width: 120px;
  height: 30px;
  left: 25px;
  top: 29px;
  background: #B92948;
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'CiscoSansTT';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #FFFFFF;

}

.left-arrow {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  margin-right: 8px;
  /* Adjust margin to separate icon from text */
}


/* ThreeButtonsComponent.css */
.action-buttons-container {
  /* Your existing styles */
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: auto;
  z-index: 999;
  /* Adjust width as needed */
  opacity: 1;
  border-radius: 10px;
  background: #1F2226;
  bottom: 20px;
}

.navigation-buttons-container {
  /* Your existing styles */
  height: 100%;
  display: flex;
  align-items: center;
  background: inherit; // Background color for input wrapper


  &-next-button {
    position: relative;
    border: none;
    background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
    /* Separator color */
    width: 6.59vw;
    /* Button width */
    height: 40px;
    /* Button height */
    border-radius: 10px;
    /* Base border radius */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    /* Button text color */
    font-family: 'CiscoSansTT-Medium';
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
    padding: 5px;
  }
  &-next-button.disabled {
    opacity: 0.5;           /* Make it look grayed out */
    cursor: not-allowed;    /* Show a disabled cursor */
    background-color: #ccc; /* Optional: adjust background color */
  }

  &-back-button {
    position: relative;
    border: none;
    background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
    /* Separator color */
    width: 6.59vw;
    /* Button width */
    height: 40px;
    /* Button height */
    border-radius: 10px;
    /* Base border radius */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    /* Button text color */
    font-family: 'CiscoSansTT-Medium';
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
    padding: 5px;
  }
  &-back-button.disabled {
    opacity: 0.5;           /* Make it look grayed out */
    cursor: not-allowed;    /* Show a disabled cursor */
    background-color: #ccc; /* Optional: adjust background color */
  }
}


.search-button {
  position: relative;
  border: none;
  background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
  /* Separator color */
  width: 8.59vw;
  /* Button width */
  height: 40px;
  /* Button height */
  border-radius: 10px;
  /* Base border radius */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  /* Button text color */
  font-family: 'CiscoSansTT-Medium';
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
}

.reset-button {
  position: relative;
  background-color: #000000;
  /* Base background color */
  border: 2px solid #000000;
  /* Separator color */
  width: 9.92vw;
  /* Button width */
  height: 40px;
  /* Button height */
  border-radius: 10px;
  /* Base border radius */
  border-color: #000000;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  /* Button text color */
  font-family: 'CiscoSansTT-Medium';
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 10px;
  padding: 5px;
}

.where-am-i-button {
  position: relative;
  background-color: #000000;
  /* Base background color */
  border: 1px solid #000000;
  min-width: 8.6vw;
  width: fit-content;
  /* Separator color */
  max-width: 9.92vw;
  /* Button width */
  height: 40px;
  /* Button height */
  border-radius: 10px;
  /* Base border radius */
  border-color: #FFEC42;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #FFFFFF;
  /* Button text color */
  font-family: 'CiscoSansTT-Medium';
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 10px 10px 10px;
  padding: 5px;

  &-marker-icon {
    margin: 0;
    padding: 0;
    width: 2.64vw;
    /* Adjust marker icon size as needed */
    height: 70px;
    /* Adjust marker icon size as needed */
    margin-bottom: 30px;

  }

}

.where-am-i-button-marker-image {

  position: absolute;
  width: 3.83vw;
  height: 90.72px;
  left: 0px;
  top: 0px;
}


.button:hover {
  background-color: #231F20;
  /* Darker shade on hover */
}

.button img {
  position: absolute;
  left: 12.43%;
  /* Icon position adjustments */
  right: 74.01%;
  top: 27.08%;
  bottom: 22.92%;
}

.button .timer {
  position: absolute;
  font-size: 11px;
  right: 10px;
  /* Timer position adjustments */
  top: 50%;
  transform: translateY(-50%);
}

.reset-button .timer {
  position: relative;
  font-size: 11px;
  top: 45%;
  left: 3%;
  transform: translateY(-50%);
}

.button:nth-child(2),
.button:nth-child(3) {
  border-left: 1px solid #000000;
  /* Separator between buttons */
}

.button-icon {
  width: 24px;
  /* Adjust icon size as needed */
  height: 24px;
  /* Adjust icon size as needed */
  margin-right: 8px;
  /* Adjust icon margin as needed */
}

.button-icon-grid {
  width: 30px;
  /* Adjust icon size as needed */
  height: 30px;
  /* Adjust icon size as needed */
  align-items: center;
}


.button-content {
  display: inline;
  align-items: center;

}

.where-am-i-button::before,
.where-am-i-button::after {
  content: '';
  position: absolute;
  height: 70%;
  /* Adjust line height */
  width: 1px;
  /* Line width */
  background-color: #424141;
  /* Line color */
  top: 10%;
  /* Adjust position of line relative to container */
}

.where-am-i-button::before {
  left: calc(-7% - 2px);
  /* Adjust position of first line */
}

.where-am-i-button::after {
  left: calc(109% - 2px);
  /* Adjust position of second line */
}

.custom-popup {

  margin: 0.6vw;
  margin-bottom: 0;
  position: absolute;
  width: 33vw;
  height: fit-content;
  max-height: 90vh;
  left: 33%;
  bottom: 10px;
  border-radius: 1.53vw;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center; // Center content horizontally
  padding: 1.3vw;
  overflow-y: auto;
  border: 1px solid;
  background: #1F2226;
  /* Inner background color */
  background-origin: border-box;
  /* Position the gradient at the border */
  background-clip: padding-box, border-box;
  /* Clip the backgrounds to the element */
  border: 1px solid;
  border-image-source: linear-gradient(360deg, rgba(0, 0, 0, 0) 11.22%, #00BCEB 100%);


  &-header {
    width: 100%;
    margin-bottom: 10px; // Space between header and buttons
    gap: 15px;

    &-popup-sub-heading {
      display: none;
      align-items: center;

      &-zone-name {
        display: none;
        font-family: 'CiscoSansTT-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 0.63vw;
        color: #FFFFFF;
        text-align: left;
      }

    }

    &-popup-title {
      width: 100%;
      font-family: 'CiscoSansTT-Medium';
      font-style: normal;
      font-weight: 600;
      font-size: 1.11vw;
      line-height: 23px;
      color: #FFFFFF;
      text-transform: capitalize;
      margin-bottom: 0.69vw; // Space below title
      &-shrink {
          white-space: nowrap; // Prevent text from wrapping to a new line
          overflow: hidden; // Hide any overflow text
          text-overflow: ellipsis; // Add ellipsis (...) to truncated text
        }
    }

    &-subtitle-content {
      display: flex;
      align-items: center;

      &-zone {
        display: none;
        align-items: center;

        &-base {
          background: #7BAFD9;
          border-radius: 11.3231px;
          padding: 5px 10px;

          &-text {
            font-family: 'CiscoSansTT-Regular';
            font-style: normal;
            font-weight: 700;
            font-size: 0.93vw;
            color: #FFFFFF;
            text-align: center;
          }
        }
      }

      &-subtitle {
        font-family: 'CiscoSansTT-Medium';
        font-style: normal;
        font-weight: 400;
        font-size: 0.97vw;
        color: #FFFFFF;
        display: none;
      }

    }

    &-description {
      margin-top: 10px;
      font-family: 'CiscoSansTTLight';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8vw;
      color: #c9c5c5;
      overflow: hidden;
      /* Hides the overflowing text */
      max-height: 12.8vh;
      /* Adjust this to limit the height initially */
      transition: max-height 0.3s ease;
      -webkit-mask-image: linear-gradient(to bottom, black 30%, transparent 100%);

    }

    &-description.expanded {
      max-height: 130vh;
      /* Large max-height for expanded state */
      -webkit-mask-image: none;

    }

    &-read-more-btn:focus {
      outline: none;
    }

    &-read-more {
      font-family: 'CiscoSansTTBold';
      cursor: pointer;
      color: #1D7FF3;
      font-size: 0.6vw;
      font-weight: 400;
      margin-top: 5px;
      background: transparent;
      border: none;
      /* Remove any border */
      padding: 0;
      /* Remove padding if any */
      display: inline-block;
      /* Ensure it stays inline */
    }

  }


  &-scheduled-sessions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 98%;
    height: 24px;
    margin-bottom: 5px;

    &-text {
      margin-left: 5px;
      width: 70%;
      font-family: 'CiscoSansTT-Medium';
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #C9C9C9;
    }

    &-date {
      width: 30%;
      font-family: 'CiscoSansTTLight';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-align: right;
      color: #FFFFFF;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 10002;

    &-button {
      width: 100%;
      height: 3vh;
      border: none;
      border-radius: 0.69vw;
      font-family: 'CiscoSansTT-Medium';
      font-style: normal;
      font-weight: 600;
      font-size: 1.11vw;
      line-height: 2.22vh;
      color: #FFFFFF;
      cursor: pointer;
    }

    &-get-directions-button {
      background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
      width: 100%;
      height: 5.3vh;
      border: none;
      border-radius: 0.69vw;
      font-family: 'CiscoSansTTRegular';
      font-style: normal;
      font-weight: 600;
      font-size: 1.11vw;
      line-height: 2.22vh;
      color: #FFFFFF;
      cursor: pointer;
    }

    &-cancel-button {
      background: #FF0000;
    }
  }

  &-navigation-popup {
    position: relative;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: 1.04vw;
    box-sizing: border-box;
    z-index: 1001;
    padding-top: 1.67vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;

    &-header {
      display: flex;
      align-items: center; // Align items vertically centered
      width: 100%; // Make the header take the full width
      position: relative;

      &-text {
        font-family: 'CiscoSansTT-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 1.04vw;
        color: #FFFFFF;
        margin-right: 0.69vw; // Space between the text and the line
      }

      &-line {
        flex-grow: 1; // Allow the line to take the remaining space
        border: 0.04vw solid #69707A;
        align-items: baseline;
      }
    }

    &-sub-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-top: 0.69vh;
      justify-content: center;
      gap: 0.2vh;

      .line {
        position: absolute;
        align-items: center;
        transform: translateY(-10%);
        height: 23vh;
        width: 8px;
        background: #00BCEB;
        z-index: 10001;
        left: 1vw;
        display: inline;
        flex: auto;
      }

      &-input-wrapper-1 {
        height: 100%;
        display: flex;
        align-items: center;
        background: inherit; // Background color for input wrapper
        padding: 0.5vw; // Padding around the input and icon
        padding-left: 0vw;
        pointer-events: none;  
        user-select: none;     
        &-icon {
          position: relative;
          z-index: 10002;
        }

        &-l {
          left: 3%;
          box-sizing: border-box;
          position: relative;
          border: 0.07vw solid #82CF5F;
          border-radius: 0.56vw;

          &-label {
            position: relative;
            top: .35vh;
            left:.4vw;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.69vw;
            color: #8C93A0;
           
          }

          &-input {
            border-radius: 0.56vw;
            width: 10vw;
            border: none;
            padding: 0.69vw;
            background: inherit;
            color: #82CF5F;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.83vw;
            pointer-events: none;  
            user-select: none;     
          }
        }
      }
      &-steps-wrapper-1-before {
        transition: opacity 0.3s ease;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        background: inherit; // Background color for input wrapper
        padding: 0.5vw; // Padding around the input and icon
        padding-left: 0vw; 
        opacity: 0.3; /* Reduced opacity for "before" card */
        pointer-events: none;  
        user-select: none;     
        &.active {
            opacity: 1; /* Full opacity for the active card */
        }
    
        &-icon {
            z-index: 10002;
        }
    
        &-l {
          width: 70%;
          left: 3.4vw;
          background: linear-gradient(180deg, #000000 25.76%, rgba(16, 18, 20, 0) 92.42%);
          transition: opacity 0.3s ease;
          opacity: 0.3;
          box-sizing: border-box;
          position: relative;       
          border-radius: 0.56vw;
   
          &.active {
            opacity: 1; /* Full opacity for active label */
        }
          &-label {
            
            position: relative; 
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.69vw;
            color: #8C93A0;
          }

          &-input {
            width: 9vw;
            border-radius: 0.56vw;
            border: none;
            padding: 0.69vw;
            background: inherit;
            color: #ffffff;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.83vw;
            pointer-events: none;  
            user-select: none;     
          }
        }
    }
      &-steps-wrapper-1 { 
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        background: inherit; // Background color for input wrapper
        padding: 0.5vw; // Padding around the input and icon
        padding-right: 0vw; // Padding around the input and icon

        &-icon {
          position: relative;
          z-index: 10002;
          left: 2.6%;
        }

        &-l {
          box-sizing: border-box;
          position: relative;
          border: 0.07vw solid #ffffff;
          border-radius: 0.56vw;
          left: 1.5vw; // Space between icon and input

          &-label {
            position: relative;
            margin-left: 0.35vw;
            top: 0.35vh;
            padding-top: 0.35vh;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.69vw;
            color: #8C93A0;
            margin-bottom: 6.25vh; // Space between the label and input
          }

          &-input {
            width: 10vw;
            border-radius: 0.56vw;
            border: none;
            padding: 0.69vw;
            background: inherit;
            color: #ffffff;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.83vw;
            pointer-events: none;  
            user-select: none;  
            resize: none;   
            height: auto;
          }
        }
      }
      &-steps-wrapper-1-after { 
        transition: opacity 0.3s ease;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        background: inherit; // Background color for input wrapper
        padding: 0.5vw; // Padding around the input and icon
        pointer-events: none;  
        user-select: none;     
        &-icon {
          z-index: 10002;
        }

        &-l {
          width: 70%;
          left: 3.8vw;
          background: linear-gradient(180deg, #000000 25.76%, rgba(16, 18, 20, 0) 92.42%);
          transition: opacity 0.3s ease;
          opacity: 0.3;
          box-sizing: border-box;
          position: relative;       
          border-radius: 0.56vw;

          &-label {
            
            position: relative; 
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.69vw;
            color: #8C93A0;
          }

          &-input {
            width: 9vw;
            border-radius: 0.56vw;
            border: none;
            padding: 0.69vw;
            background: inherit;
            color: #ffffff;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.83vw;
            pointer-events: none;  
            user-select: none;     
          }
        }
      }

      &-text {
        font-family: 'CiscoSansTTLight';
        font-style: normal;
        font-weight: 300;
        font-size: 0.6vw;
        width: 100%;
        align-items: left;
        color: #FFFFFF;

      }

      &-input-wrapper-2 {
        height: 100%;
        display: flex;
        align-items: center;
        background: inherit; // Background color for input wrapper
        padding: 0.5vw; // Padding around the input and icon
        padding-left: 0vw;
        pointer-events: none;  
        user-select: none;     
        &-icon {
          position: relative;
          margin-left: 0.1vw; // Space between icon and input
          z-index: 10002;
        }

        &-l {
          left: 3%;
          border: 0.07vw solid #00BCEB;
          box-sizing: border-box;
          position: relative;
          border-radius: 0.56vw;

          &-label {
            position: relative;
            margin-left: 0.35vw;
            top: 0.35vh;
            padding-top: 0.35vh;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.69vw;
            color: #8C93A0;
            margin-bottom: 6.25vh; // Space between the label and input
          }

          &-input {
            width: 10vw;
            flex: 1;
            border: none;
            border-radius: 0.56vw;
            padding: 0.69vw;
            background: inherit;
            color: #00BCEB;
            font-family: 'CiscoSansTT-Medium';
            font-size: 0.83vw;
            overflow-x: auto;
            pointer-events: none;  
            user-select: none;     
            &-edit {
              position: absolute;
              right: 0.02vw; // Position the edit button inside the input on the right end
              transform: translateY(-50%);
              background: inherit;
              border-radius: 0.56vw;
              width: 2.64vw;
              height: 1.39vh;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              cursor: pointer;
              z-index: 10003;
              font-size: 0.69vw;
            }
          }
        }
      }
    }

    &-sub-content::after {

      margin-bottom: 2vh;
      content: '';
      position: relative;
      height: 0.07vh;
      width: 100%;
      background-color: #888888;
    }

    &-footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 100%;

      &-title {
        width: 60%;
        font-family: 'CiscoSansTT-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 0.83vw;
        line-height: 2.56vh;
        color: #FFFFFF;
        text-transform: capitalize;
        margin-bottom: 0.69vh; // Space below title
        text-align: center; // Center align title text
      }

      &-bottom {
        width: 100%;
        height: 100%; // Adjust the height as needed
        position: relative; // Ensure proper positioning of child elements
        background-size: cover; // Ensure the image covers the entire element
        background-position: center; // Center the background image
        background-repeat: no-repeat; // Prevent background image repetition
        display: flex;
        justify-content: center; // Center horizontally

        &-qr-code {
          width: fit-content;
          height: fit-content;
          position: absolute; // Ensure the QR code is positioned correctly
          bottom: 10%; // Align to the bottom and adjust the distance as needed
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 0.69vw;
          z-index: 10001;
          display: flex;
          align-items: center;
          justify-content: center;

          canvas {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            background: transparent;
          }
        }
      }

      &-inner {
        width: 99%;
        height: 98%;
        background-color: #1F2226;
        z-index: 1001;
        position: relative;
        top: 1%;
        left: 0.5%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-bottom {
          flex-direction: row;
          align-items: center;
          gap: 3px;
          display: flex;
          font-size: 13px;
          margin-top: 10px;
          font-family: "CiscoSansTT-Medium";
          cursor: pointer;
        }

      }

      &-active {
        flex-direction: column;
        background: none;
        transform: scale(2);
        position: fixed;
        top: 30%;
        left: 40%;
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10004;
        backdrop-filter: blur(100000px);
        box-shadow: 0 400px 800px rgba(0, 0, 0, 0.2);
        z-index: 19004;
        background: linear-gradient(180deg, #1D2832 20.22%, #30D557 136.58%);
        border-radius: 10px;

        &-inner-bottom {
          flex-direction: row;
          align-items: center;
          gap: 3px;
          display: flex;
          font-size: 13px;
          margin-bottom: 10px;
          font-family: "CiscoSansTT-Medium";
          cursor: pointer;
        }
      }

    }
  }

  &-navigation-popup::-webkit-scrollbar {
    display: none;
  }

}

.session-tables {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Space between session tables */
  overflow: hidden;
  max-height: 29vh;
  transition: max-height 0.3s ease;
  -webkit-mask-image: linear-gradient(to bottom, black 30%, transparent 100%);

  &-read-more {
    height: 100%;
    font-family: 'CiscoSansTTBold';
    cursor: pointer;
    color: #1D7FF3;
    font-size: 0.6vw;
    font-weight: 400;
    margin-top: 5px;
    background: transparent;
    border: none;
    /* Remove any border */
    padding: 0;
    /* Remove padding if any */
    display: inline-block;
    /* Ensure it stays inline */
  }

  &.expanded {
    overflow: auto;
    max-height: 60vh;
    -webkit-mask-image: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.session-tables::-webkit-scrollbar {
  display: none;
}

.session-table {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  /* Three equal columns */
  grid-template-rows: repeat(4, auto);
  /* Adjust based on your grid setup */

  gap: 0;
  /* Remove gap between cells to align borders */
  width: 100%;
  /* Full width */
  max-width: 39.7vw;
  /* Max width */
  margin: auto;
  /* Center the table */
  border-radius: 10px;
  border: 1px solid #4B525C;
  /* Borders for each cell */
  background: #282B30;
  margin-bottom: 20px;
}


.session-table>div {

  padding-top: 10px;
  /* Cell padding */


  /* Cell padding */
  background: #282B30;
  /* Optional: Cell background color */
}

.session-code-text {
  padding-left: 10px;
  grid-column: 1 / 2;
  /* First column */
  font-family: 'CiscoSansTTLight';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #C9C9C9;
  background: #383C42;
  padding-bottom: 10px;
  border-bottom: 1px solid #4B525C;
  border-top-left-radius: 10px;
}

.session-code {
  padding-left: 5px;
  padding-bottom: 10px;
  grid-column: 2 / 4;
  /* Second column */
  font-family: 'CiscoSansTT-Medium';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  background: #383C42;
  border-bottom: 1px solid #4B525C;
  border-top-right-radius: 10px;
}

.session-name-value {
  padding-bottom: 10px;
  padding-left: 10px;

  /* Borders for each cell */
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  /* Span across the first two columns */
  font-family: 'CiscoSansTT-Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #FFFFFF;
}

.screensaver-overlay {
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9012;
  backdrop-filter: blur(5px);

  &-popup {
    position: relative;
    width: fit-content;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Semi-transparent black overlay */
    backdrop-filter: blur(5px);
    object-fit: contain;
  }

  &-image {
    z-index: 9012;
    width: 100%;
    height: 100%;
    object-fit: contain;
    backdrop-filter: blur(5px);
    animation: flipAnimation 1s ease-in-out;
  }


  @keyframes flipAnimation {
    0% {
      transform: rotateY(90deg);
      opacity: 0;
    }

    100% {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
}


.speaker-name-text {
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 10px;
  grid-column: 1 / 4;
  /* The new third column */
  grid-row: 3 /4;
  border-bottom: 1px solid #4B525C;
  /* First column */
  font-family: 'CiscoSansTT-Medium';
  font-style: normal;
  width: 100%;
  font-size: 13px;
  color: #FFFFFF;
  align-items: center;
  /* Vertically center */
  display: flex;
  border-top-right-radius: 10px;

}

.speaker-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  grid-column: 1 / 4;
  grid-row: 4 / 4;
  font-family: 'CiscoSansTT-Medium';
  color: #FFFFFF;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

}

.speaker-name {
  border-bottom-left-radius: 10px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #C9C9C9;
  width: 100%;
}

.job-title {
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #C9C9C9;
  border-bottom: 1px solid #4B525C;
  width: 100%;
}

.schedule-time-column {
  height: fit-content;
  grid-column: 3 / 4;
  grid-row: 2 / 4;
  /* Adjust to the last row or desired row */
  font-family: 'CiscoSansTT-Medium';
  font-style: normal;
  font-size: 10px;
  color: #FFFFFF;
  display: flex;
  align-items: right;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-bottom-left-radius: 10px;
  margin-right: 5px;

  &-time {
    height: fit-content;
    padding-bottom: 5px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  &-division {
    padding-bottom: 5px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
}



.custom-popup-left {
  width: 17vw;
  margin-left: 0;
  left: 0;
  top: 13.68vh;
  left: 1.20vw;
  max-height: 82vh;
  position: absolute;
  overflow: hidden;
}

.custom-popup-close-button {
  position: absolute;
  top: 10px; // Adjust as needed
  right: 10px; // Adjust as needed
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 999;
}

.cisco-spaces-popup-overlay {
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9012;
  backdrop-filter: blur(5px);

  &-popup {
    position: relative;
    width: fit-content;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Semi-transparent black overlay */
    backdrop-filter: blur(5px);
    object-fit: contain;
  }

  &-image {
    z-index: 9012;
    width: 100%;
    height: 100%;
    object-fit: contain;
    backdrop-filter: blur(5px);
    /* Ensures the image covers the entire container */
  }

  &-close {
    z-index: 9012;
    position: absolute;
    right: 2vw;
    top: 3vh;
    cursor: pointer;
    padding: 10px;
    background-repeat: no-repeat;
    // background-image: url('../assets/images/cisco-spaces-popup-close.svg');
  }
}

.ai-popup-overlay{
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.308);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9012;
  backdrop-filter: blur(5px);
  

  &-popup {
    position: relative;
    width: fit-content;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Semi-transparent black overlay */
    backdrop-filter: blur(5px);
    object-fit: contain;
    align-items: center;
    border-radius: 21px;
    background-color: #1F2226;
    padding:1vw;
    &-card {
      flex-direction: column;
      align-items: center;
      border-radius: 21px;
      background-color: #1F2226;
      border-image-source: linear-gradient(360deg, rgba(0, 0, 0, 0) -3.43%, #4B525C 100%);    
      position: relative;
      width: 25vw;
      height: 100%;
      display: flex;
      justify-content: space-between;
      /* Semi-transparent black overlay */
      backdrop-filter: blur(5px);
      object-fit: contain;

      &-title{
        display: flex;
        flex-direction: column;
        align-items: center; /* Horizontal center alignment */
        justify-content: center; /* Vertical center alignment */
        text-align: center; /* Ensures multi-line text is center-aligned */
        padding: 5px;
        padding-top: 20px;
        width: 100%;
        font-family: "CiscoSansTTBold";
      }

      &-logo {
        width: 100%;
        height: 14vh;
      }

      &-logo-name {
        font-size: 13px;
        background-color: #1F2226;
        display: flex;
        flex-direction: column;
        align-items: center; /* Horizontal center alignment */
        justify-content: center; /* Vertical center alignment */
        text-align: center; /* Ensures multi-line text is center-aligned */
        padding: 2px;
        width: 100%;
        font-family: "CiscoSansTT-Medium";
      }

      &-qr-place-holder{
        width: 50%;
      }
      &-bottom-scan-again-button{
        position: relative;
        border: none;
        background: linear-gradient(180deg, #1D7FF3 0%, #113D7D 100%);
        /* Separator color */
        width: 8.59vw;
        /* Button width */
        height: 40px;
        /* Button height */
        border-radius: 10px;
        /* Base border radius */
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        /* Button text color */
        font-family: 'CiscoSansTT-Medium';
        font-size: 15px;
        font-weight: 400;
        text-align: left;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 10px 10px 10px 10px;
        padding: 10px 10px 10px 10px;
      }
      &-bottom-button{
      flex-direction: row;
      column-gap:1vw;
      align-items: center;
      display: flex;
      
        &-help {
          cursor: pointer;
          transform: scale(1.2);
          width: 2vw;
          padding-bottom: 10px;
        }
      
        &-close {
          cursor: pointer;
          transform: scale(1.2);
          width: 4vw;
          padding-bottom: 10px;
        }
      }
    }
  }

  &-image {
    z-index: 9012;
    width: 100%;
    height: 100%;
    object-fit: contain;
    backdrop-filter: blur(5px);
    /* Ensures the image covers the entire container */
  }


}
.android-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9014;
  /* Just below the loader */
}

.android-loader {
  border: 8px solid grey;
  border-radius: 50%;
  border-top: 8px solid #00BCEB;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  z-index: 9015;
  /* High z-index to appear above other content in pointr-container */
  position: absolute;
  /* Position relative to pointr-container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  /* Adjust width as needed */
  padding: 6px;
  border-radius: 15px;
   background: #000000;
  /* Main background gradient */
  color: #ffffff;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  &-active{
    transform: scale(1.2);
  }
  &-left {
      display: flex;
      flex-direction: column;
      align-items: left;
      width: 40%;
      /* Adjust width as needed */
      padding: 6px;
      border-radius: 15px;
      /* Main background gradient */
      color: #ffffff;
      font-family: Arial, sans-serif;
  
      &-header {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: left;
      }
  
      &-text {
        font-size: 10px;
        color: #d1d1d1;
        text-align: left;
        margin-bottom: 20px;
      }
    }

  &-right {
    display: flex;
      flex-direction: column;
      align-items: center;
      width: 60%;
      /* Adjust width as needed */
      padding: 6px;
      border-radius: 15px;
      /* Main background gradient */
      color: #ffffff;
      font-family: Arial, sans-serif;
      &-top {
           
        border: 4px solid #00ff88; // Green color for the first b
         
         &-qr-code-container {
          width: 6vw;
          height: 5.9vw;
          position: relative; // Ensure the QR code is positioned correctly
          background-size: contain;
          background-repeat: no-repeat;
          z-index: 10001; // Make sure it's on top of other content
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 1vw;
          border-radius: 5px;
          top:1vw;
 
          canvas {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            background: transparent;
            border-radius: inherit;
          }
        }
      
        // Add the first border ring
        &-qr-code-container::before {
          content: '';
          position: absolute;
          width: calc(100% + 1.2vw);
          height: calc(100% + 1.2vw);
         // border: 4px solid #00ff88; // Green color for the first border
          border: 4px solid #08A4F4;
          border-radius: 1.2vw;
          opacity: 1;
          box-sizing: border-box;
          z-index: 10002; // Ensure it's above the container
         
        }
      
        // Add the second border ring
        &-qr-code-container::after {
          content: '';
          position: absolute;
          width: calc(100% + 2vw);
          height: calc(100% + 2vw);
          border: 2px solid #7FE14F;
          border-radius: 1.2vw;
          opacity: 0.6;
          box-sizing: border-box;
          z-index: 10003; // Ensure it's above the previous borders
        }
      
        // Add the third border ring
        
      }
      

    &-bottom {
      &-expand-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        color: #ffffff;
        background-color: #ffffff00;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: transform 0.2s ease;
        border: 0.76px solid #00BCEB;
        padding: 5px;
        margin-top: 35px;
      }

      &-expand-button:hover {
        transform: scale(1.05);
      }
    }

  }
}
/*
.poi-popup {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.poi-popup-title {
  margin: 0;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.poi-popup-description {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  margin-bottom: 15px;
}

.poi-popup-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.poi-popup-button:hover {
  background-color: #0056b3;
}

.poi-popup-container {
  max-width: none;
  overflow: hidden;
}*/


button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;

}

button:hover {
  background-color: #0056b3;
}

.content-container {
  margin-top: 20px;
}
/* Hide the container when not active */
.container-hidden {
  display: none;
}

/* Show the container when active */
.container-visible {
  display: block;

}

.home-page{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

 

.logo {
  width: 100px;  /* Adjust size of the logo */
  height: auto;
}
 
.logo-container {
  background-color: #ffffff;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  width: 28%; /* Set width of the button */
  height: 20%; /* Set height of the button */
  background-image:  url('../assets/images/spaces-button-image.png');
  background-size:contain; /* Makes sure the image covers the button area */
  background-position: center; /* Ensures the image is centered within the button */
  background-repeat: no-repeat; /* Prevents the image from repeating if it's smaller than the button */
  border: none; /* Optional: remove the border if it's a button */
  border-radius: 5px; /* Optional: for rounded corners */
}

.logo-container:hover {
  opacity: 0.8; /* Optional: makes the button slightly transparent on hover */
}


.logo-container-1 {
  background-color: #ffffff;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  width: 28%; /* Set width of the button */
  height: 20%; /* Set height of the button */
  background-image:  url('../assets/images/ai-assistant-button.png');
  background-size: cover; /* Makes sure the image covers the button area */
  background-position: center; /* Ensures the image is centered within the button */
  background-repeat: no-repeat; /* Prevents the image from repeating if it's smaller than the button */
  border: none; /* Optional: remove the border if it's a button */
  border-radius: 5px; /* Optional: for rounded corners */
}

.logo-container-1:hover {
  opacity: 0.8; /* Optional: makes the button slightly transparent on hover */
}

.clai2025-page{
  background-image:  url('../assets/images/cisco-live-home-accent.png');
  background-size: cover; /* Makes sure the image covers the button area */
  background-position: center; /* Ensures the image is centered within the button */
  background-repeat: no-repeat; /* Prevents the image from repeating if it's smaller than the button */
  background-color: #ffffff00;
}


.logo-container .arrow {
  position: relative;
  width: 30px; /* Set the width of the arrow */
  height: 30px; /* Set the height of the arrow */
  background-image:  url('../assets/images/right-arrow-circle.png');
  background-size: contain; /* Ensures the arrow fits within the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  pointer-events: none; /* Prevents the arrow from interfering with clicks */
}

.logo-container-1 .arrow {
  position: relative;
  width: 30px; /* Set the width of the arrow */
  height: 30px; /* Set the height of the arrow */
  background-image:  url('../assets/images/right-arrow-circle.png');
  background-size: contain; /* Ensures the arrow fits within the container */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  pointer-events: none; /* Prevents the arrow from interfering with clicks */
}