.faq-container {
  position: absolute;
  bottom: 2.87vh;
  width: 32vw;
  right: 1.50vw;
  z-index: 999;
  background: #1F2226;
  max-height: 82vh;
  border-image-source: linear-gradient(360deg, rgba(0, 0, 0, 0) -3.43%, #4B525C 100%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 82vh;
  &-top {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    padding-left: 15px;

    &-back-button {
      position: relative;
      width: 1vw;
      height: 15%;
      cursor: pointer;
      transform:scale(0.6);
    }

    &-title {
      width: 91%;
      font-family: "CiscoSansTTBold";
      font-size: 15px;
      align-items: center;
    }

    &-close-button {
      position: relative;
      width: 1vw;
      height: 15%;
      cursor: pointer;
      transform:scale(2);
    }
  }

  &-bottom {
    overflow: auto;
    padding: 10px;

    &-faq-item {
      margin: 10px 0;
      padding: 15px;
      border-radius: 10px;
      cursor: pointer;
      background: #2D3238;
    }


    &-faq-question {
      font-family: "CiscoSansTTRegular";
      font-size: 18px;
      font-weight: bold;
    }

    &-faq-answer {
      font-size: 14px;
      margin-top: 10px;
      line-height: 1.5;
     // border-top: 1px solid #444;
      padding-top: 10px;
      font-family: "CiscoSansTTLight";
    }

    /* Active state styling */
    &-faq-item.active {
      background: #1F2226;
      border-top: 1.5px solid #146557;
      border-left: 1px solid #146557a9;
      border-right: 1px solid #146557a9;
      border-bottom: 1px solid #146557a9;

          }
    

    &-faq-item.active &-faq-answer {
      display: block;

    }
  }
}


.inline-faq-container {
  display: flex;
  width: 31.9vw;
  max-height: 45vh;
  min-height: 45vh;
  border: 1px solid #333;
//  border-radius: 8px;
  background: #000000;
  color: white;
  font-family: Arial, sans-serif;
  flex-direction: column;
  position: relative;
}

.inline-faq-header {
  margin-top: 5px;
  position: relative;
  max-height: 3vh;
  min-height: 3vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #000000;
  align-items: center;
  text-align: center;
  font-family: "CiscoSansTT-Medium";
  &-text{ 
    width: 92%;
    text-align: center;
    font-family: "CiscoSansTTBold";
    font-size: 14px;
  }
  &-button-close {
    width: 8%;
    cursor: pointer;
    transform: scale(1.2);
    width: 1.5vw;
    align-content:last baseline;
    align-self: right;
  }
}

.inline-faq-bottom {
  position: relative;
  display: flex;
  width: 31.8vw;
  max-height: 38vh;
  min-height: 38vh;
 // border: 1px solid #333;
//  border-radius: 8px;
  background: #000000;
  color: white;
  font-family: Arial, sans-serif;
  flex-direction: row;
}
.inline-faq-sidebar {
  max-height: 40vh;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  width: 50%;
  background: #000000;
  padding: 10px;
  overflow: auto;
}

.inline-faq-right-sidebar {
  margin-top: 1.9vh;
  flex: 1;
  background-clip: content-box;
  padding: 10px;
  color: white;
  font-family: "CiscoSansTTLight";
  border-radius: 5px;
  min-width: 48%;
  max-width: 48%;
  overflow-y: auto;
  max-height: 37vh;
  min-height: 37vh;
  padding-right: 0.8vw;
  padding-left: 0.5vw;
  gap: 2px;
  background-color: #000000;
  border-top: 1.5px solid #146557;
  border-left: 1.5px solid #146557a9;
  border-right: 1.5px solid #146557a9;
  border-bottom: 1.5px solid #146557a9;
  padding: 8px;
  overflow: auto;
}

.inline-faq-question {

 // background: transparent;
  border: none;
  color: white;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  background: #2D3238;
  width: 14vw;
  height: 6vh;
  min-height: 6vh;
  font-family: "CiscoSansTTRegular";
  overflow: hidden;

}

.inline-faq-question.active {
  background: linear-gradient(93.16deg, rgba(48, 213, 87, 0.25) -19.57%, rgba(0, 188, 235, 0.25) 121.06%);
  //border-left: 4px solid #0f766e;
}

.inline-faq-content {
  width: 100%;
  flex: 1;
  //padding: 5px;
  //border: 2px solid #0f766e;
}

h3 {
  font-size: 16px;
  margin-bottom: 5px;
  padding-left: 0.5vw;
  font-family: "CiscoSansTT-Medium";
  text-align: left;
}

p {
  padding-left: 0.5vw;
  text-align: left;
  font-size: 14px;
  opacity: 0.8;
  font-family: "CiscoSansTTRegular";
}
.inline-faq-right-sidebar::-webkit-scrollbar {
  display: none;
}

.inline-faq-sidebar::-webkit-scrollbar {
  display: none;
}
